export default function date() {
    let months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let now       = new Date();
    let thisMonth = months[now.getMonth()]; // getMonth method returns the month of the date (0-January :: 11-December)
    let thisYear = now.getFullYear();

    let monthEls = document.querySelectorAll('.dyn-month');
    let yearEls = document.querySelectorAll('.dyn-year');
    let dateEls = document.querySelectorAll('.output-today');

    const nth = function(d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    }

    monthEls.forEach(function(el, index){
        el.textContent = thisMonth;
    });

    yearEls.forEach(function(el, index){
        el.textContent = thisYear;
    });

    dateEls.forEach(function(el, index){
        el.textContent = now.getDate() + nth(now.getDate()) + ' ' + thisMonth + ' ' + thisYear;
    });


}
