export default function cf7Redirect() {

    var wpcf7Elm = document.querySelectorAll( '.wpcf7' );
    if(wpcf7Elm){

        for(var i = 0; i < wpcf7Elm.length; i++) {
            wpcf7Elm[i].addEventListener( 'wpcf7mailsent', function( event ) {
                var elm = this;
                var inputVal = elm.querySelector('[type=submit]');
                var redirect = elm.querySelector('[name="kk-redirect"]')

                inputVal.value = 'Complete!';

                var queryString = '?';

                if(event.detail.inputs){
                    event.detail.inputs.forEach(function(inp, ind){
                        if(inp.name !== "kk-redirect" && inp.name !== "planet"){
                            queryString += inp.name + '=' + inp.value + '&';
                        }
                    });
                }

                // dont send to ty page
                if(document.body.classList.contains('page-template-template-questionnaire')){
                    queryString = '';
                }

                // strip trailing &
                queryString = queryString.replace(/&+$/, "");

                if(redirect){
                    window.location.href = redirect.value + queryString;
                }
            });

            wpcf7Elm[i].addEventListener('submit', function(event) {
                var inputVal = this.querySelector('[type=submit]');
                
                if(inputVal) {
                    inputVal.setAttribute('data-value', inputVal.value);
                }
                inputVal.value = 'Processing...';

            })

            wpcf7Elm[i].addEventListener('wpcf7invalid', function() {
                var inputVal = this.querySelector('[type=submit]');

                if(inputVal && inputVal.getAttribute('data-value')) {
                    inputVal.value = inputVal.getAttribute('data-value');
                }
            })
        }
    }
 }   
    