/**
 * Global JS here
 * Page specific JS shouldn't go here
 */
import contentLoaded from './vendor/contentloaded.js'
import images from './images.js'
import phoneConcat from './phone-concatenation.js'
import polyfill from './polyfills.js'
import smoothScroll from './smooth-scroll.js'
import stickyHeader from './sticky-header.js'
import viewAnimation from './view-animation.js'
import date from './date.js'
import multistep from './multi-step-form'
import cf7Redirect from './cf7'
import { initModals, exitIntent } from './modal';

contentLoaded(window, function (e) {
	document.body.classList.add('ready')
	viewAnimation();
	polyfill()
	phoneConcat()
	images()
	smoothScroll()
	stickyHeader()
	date()
    initModals()
    exitIntent()
    cf7Redirect()

    if(document.body.classList.contains('page-template-template-questionnaire')){
	    multistep()
    }
})
